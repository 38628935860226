<template>
  <div class="device-test">
    <div class="container py-4">
      
      <h2 class="text-center">用手機紀錄軌跡</h2>

      <div v-if="gpsPermission === 1">
        <div class="input-div">
          <label>裝置ID</label>
          <input class="form-control" v-model="deviceId" :disabled="timerStatus === 1"/>
        </div>

        <div class="mt-4 text-center">
          <button class="btn btn-outline-danger" v-if="timerStatus === 0" @click="startTimer">開始</button>
          <button class="btn btn-outline-secondary" v-if="timerStatus === 1" @click="stopTimer">結束</button>
        </div>

        <div class="border-rounded bg-white p-4 mt-4" v-if="timerStatus === 1">
          <p class="text-danger">請勿關閉或切換離開此頁面，方能持續記錄</p>
          <p style="overflow-wrap: break-word;">{{ JSON.stringify(savedRecord) }}</p>
        </div>
      </div>

      <div v-if="gpsPermission === 2">
        <p>無法取得位置相關權限</p>
      </div>
      
    </div>
  </div>
</template>

<script>
// import axios from 'axios';

// @ is an alias to /src

export default {
  name: 'DeviceTest',
  data() {
    return {
      deviceId: 'test000',
      gpsPermission: 0,  // 0: unknown, 1: accept, 2: denied,
      timer: null,
      timerStatus: 0, // 0: not start, 1: started.
      savedRecord: {

      },
    };
  },
  computed: {
    
  },
  components: {
  },
  beforeDestroy() {
    this.stopTimer();
  },
  async mounted() {
    navigator.geolocation.getCurrentPosition(() => {
      this.gpsPermission = 1;
    }, () => {
      this.gpsPermission = 2;
    });
  },
  methods: {
    startTimer() {
      if (this.timer === null) {
        this.timer = setInterval(async () => {
          await this.recordGPS();
        }, 10 * 1000);
        this.timerStatus = 1;
      }
    },
    stopTimer() {
      if (this.timer !== null) {
        clearInterval(this.timer);
        this.timer = null;
        this.timerStatus = 0;
      }
    },
    async recordGPS() {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const gpsRecord = {
          deviceId: this.deviceId,
          time: new Date().getTime(),
        }
        if ((Math.random() * 100) > 10) {
          gpsRecord.lat = position.coords.latitude;
          gpsRecord.lng = position.coords.longitude;
        }
        this.savedRecord = Object.assign({}, gpsRecord);
        await this.$store.dispatch('api/saveTestPromise', gpsRecord);
        console.log(gpsRecord);
      }, () => {
        this.stopTimer();
      });
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
